var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "el-radio-group",
          {
            on: { change: _vm.handleChangeAction },
            model: {
              value: _vm.type,
              callback: function ($$v) {
                _vm.type = $$v
              },
              expression: "type",
            },
          },
          [
            _c("el-radio-button", { attrs: { label: "class" } }, [
              _vm._v("Lớp"),
            ]),
            _c("el-radio-button", { attrs: { label: "all" } }, [
              _vm._v("Trường"),
            ]),
          ],
          1
        ),
        _vm.type == "class"
          ? _c(
              "span",
              {
                staticClass: "detail-name",
                staticStyle: { "margin-left": "50px", "margin-top": "20px" },
              },
              [
                _c(
                  "el-radio-group",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.searchByProperties()
                      },
                    },
                    model: {
                      value: _vm.dataSearch.typeSchool,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearch, "typeSchool", $$v)
                      },
                      expression: "dataSearch.typeSchool",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "grade" } }, [
                      _vm._v("Xem theo khối"),
                    ]),
                    _c("el-radio", { attrs: { label: "class" } }, [
                      _vm._v("Xem theo lớp"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "span",
                  [
                    _c("el-date-picker", {
                      staticClass: "button-left-status",
                      staticStyle: { "margin-left": "25px", width: "140px" },
                      attrs: {
                        clearable: false,
                        "value-format": "yyyy-MM-dd",
                        format: "dd-MM-yyyy",
                        type: "date",
                        placeholder: "Chọn ngày",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.searchByProperties()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "date", $$v)
                        },
                        expression: "dataSearch.date",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.type == "all"
          ? _c(
              "span",
              {
                staticClass: "detail-name",
                staticStyle: { "margin-left": "50px", "margin-top": "20px" },
              },
              [
                _c("el-date-picker", {
                  staticClass: "button-left-status",
                  staticStyle: { width: "265px" },
                  attrs: {
                    clearable: false,
                    "value-format": "yyyy-MM-dd",
                    format: "dd-MM-yyyy",
                    type: "daterange",
                    "unlink-panels": "",
                    "range-separator": "-",
                    "start-placeholder": "Ngày bắt đầu",
                    "end-placeholder": "Ngày kết thúc",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.searchByPropertiesSchoolDate()
                    },
                  },
                  model: {
                    value: _vm.dataSearchSchoolDate.dateList,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearchSchoolDate, "dateList", $$v)
                    },
                    expression: "dataSearchSchoolDate.dateList",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "margin-top": "20px" } },
      [
        _c(
          "el-row",
          { staticStyle: { "margin-bottom": "10px" }, attrs: { gutter: 50 } },
          [
            _c(
              "el-col",
              { staticStyle: { color: "white" }, attrs: { span: 12 } },
              [
                _c("div", { staticClass: "grid-content bg-purple" }, [
                  _c("div", { staticClass: "content-line-1 color-2" }, [
                    _c("i", { staticClass: "el-icon-chat-line-square" }),
                    _vm._v(
                      "\n          Đã duyệt:\n          " +
                        _vm._s(
                          this.type == "class"
                            ? this.loda.sumBy(this.dataAlbumYes)
                            : this.loda.sumBy(this.dataDateSchoolAlbumYes)
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "el-col",
              { staticStyle: { color: "white" }, attrs: { span: 12 } },
              [
                _c("div", { staticClass: "grid-content bg-purple" }, [
                  _c("div", { staticClass: "content-line-1 color-4" }, [
                    _c("i", { staticClass: "el-icon-edit-outline" }),
                    _vm._v(
                      "\n          Chưa duyệt:\n          " +
                        _vm._s(
                          this.type == "class"
                            ? this.loda.sumBy(this.dataAlbumNo)
                            : this.loda.sumBy(this.dataDateSchoolAlbumNo)
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm.loadChart
          ? _c("spinner-custom")
          : _c("div", [
              _vm.type == "class"
                ? _c(
                    "span",
                    [
                      _vm.loaded
                        ? _c("ChartBar", {
                            staticStyle: { height: "350px" },
                            attrs: {
                              chartData: _vm.dataConllection.chartData,
                              options: _vm.dataConllection.options,
                              checkLable: true,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == "all"
                ? _c(
                    "span",
                    [
                      _vm.loaded
                        ? _c("ChartBar", {
                            attrs: {
                              chartData: _vm.dataConllection2.chartData,
                              options: _vm.dataConllection2.options,
                              checkLable: true,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
      ],
      1
    ),
    _c("div", { staticClass: "album-date" }, [
      _vm.type == "class"
        ? _c(
            "span",
            { staticClass: "detail-name" },
            [
              this.getAppTypeUserLogin == "plus"
                ? _c(
                    "el-select",
                    {
                      staticClass: "button-left-status",
                      staticStyle: { width: "140px" },
                      attrs: { clearable: true, placeholder: "Chọn khối" },
                      on: {
                        change: function ($event) {
                          return _vm.searchDataMethod()
                        },
                      },
                      model: {
                        value: _vm.dataSearchDate.idGrade,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearchDate, "idGrade", $$v)
                        },
                        expression: "dataSearchDate.idGrade",
                      },
                    },
                    _vm._l(_vm.gradeOfSchoolList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.gradeName },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "el-select",
                {
                  staticClass: "button-left-status",
                  staticStyle: { width: "140px" },
                  attrs: {
                    filterable: "",
                    clearable: this.getAppTypeUserLogin == "plus",
                    placeholder: "Chọn lớp",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.searchByPropertiesDate()
                    },
                  },
                  model: {
                    value: _vm.dataSearchDate.idClass,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearchDate, "idClass", $$v)
                    },
                    expression: "dataSearchDate.idClass",
                  },
                },
                _vm._l(_vm.classList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.className },
                  })
                }),
                1
              ),
              _c("el-date-picker", {
                staticClass: "button-left-status",
                staticStyle: { width: "265px" },
                attrs: {
                  clearable: false,
                  "value-format": "yyyy-MM-dd",
                  format: "dd-MM-yyyy",
                  type: "daterange",
                  "unlink-panels": "",
                  "range-separator": "-",
                  "start-placeholder": "Ngày bắt đầu",
                  "end-placeholder": "Ngày kết thúc",
                },
                on: {
                  change: function ($event) {
                    return _vm.searchByPropertiesDate()
                  },
                },
                model: {
                  value: _vm.dataSearchDate.dateList,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearchDate, "dateList", $$v)
                  },
                  expression: "dataSearchDate.dateList",
                },
              }),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { gutter: 50 },
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { color: "white" },
                          attrs: { span: 12 },
                        },
                        [
                          _c("div", { staticClass: "grid-content bg-purple" }, [
                            _c(
                              "div",
                              { staticClass: "content-line-1 color-2" },
                              [
                                _c("i", {
                                  staticClass: "el-icon-chat-line-square",
                                }),
                                _vm._v(
                                  "\n                Đã duyệt:\n                " +
                                    _vm._s(
                                      this.loda.sumBy(this.dataDateAlbumYes)
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { color: "white" },
                          attrs: { span: 12 },
                        },
                        [
                          _c("div", { staticClass: "grid-content bg-purple" }, [
                            _c(
                              "div",
                              { staticClass: "content-line-1 color-4" },
                              [
                                _c("i", {
                                  staticClass: "el-icon-edit-outline",
                                }),
                                _vm._v(
                                  "\n                Chưa duyệt:\n                " +
                                    _vm._s(
                                      this.loda.sumBy(this.dataDateAlbumNo)
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.loadChart1
                    ? _c("spinner-custom")
                    : _c("div", [
                        _vm.type == "class"
                          ? _c(
                              "span",
                              [
                                _vm.loaded
                                  ? _c("ChartBar", {
                                      staticStyle: { height: "350px" },
                                      attrs: {
                                        chartData:
                                          _vm.dataConllection1.chartData,
                                        options: _vm.dataConllection1.options,
                                        checkLable: true,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }